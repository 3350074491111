<template>
  <div class="flex md12">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">

    <div :style="topScaleRatio">
      <div class="home_container">
        <div class="home_screen">
          <div class="Dcompany_logo"><img class="Dlogo" src="@/assets/infyn.png" /></div>
          <div style="position: absolute; margin-left: 641px; cursor: pointer;">
            <img src="@/assets/leftarrow.png" height="12"  style="position: absolute; margin-left: 3px; margin-top: 7px;" />
            <span style="margin-left: 19px;"><a style="color: white;" href="https://www.hepi.tv/">View Website</a></span>
          </div>
          <img src="@/assets/tv.png" class="min_max">
          <video 
            class="Dvideo_screen"
            src="@/assets/HepiVideoHome.mp4"
            autoplay
            muted
            playsinline
            loop
          ></video>
          <div class="home_text">Create an immersive in-room experience with Hepi</div>
          <div class="home_text2">make your guest feel extra special</div>
          <div class="buttonSet">
            <button class="button_tag" @click="modal('custom')" style="border: white; color: black; background-color: white;">Customize Now</button>
            <button
              class="button_tag"
              style="background-color: #f67218; border: #f67218; color: white;"
              @click="placeOrder('confirm')">Order Demo Kit</button>
          </div>
        </div>
        
        <div v-if="modalForm" style="width: 800px; margin: 0 auto; position: relative; text-align: center;">
          <div class="popupView">
            <img src="@/assets/popup_line.png" class="popup_line"/>
            <img class="popup_image" v-if="modalForm" src="@/assets/PopUp.png" />
            <center>
              <va-card v-if="modalForm" class="popupCard" style="box-shadow: black;">
                <i 
                  class="fa fa-close" 
                  style="position: absolute; color: white; left: 667px; top: 36px; cursor: pointer;" 
                  @click="closeModal()">
                </i>

                <center style="margin-top: 31px;">
                  <center style="width: 220px; font-size: 16px; color: white; text-size-adjust: 60%;">Register your details to</center>
                  <center style="width: 292px; font-size: 17px; font-weight: 600; color: white; text-size-adjust: 60%;">Customise your own product</center>
                </center>

                <center class="mt-3">
                  <div>
                    <input 
                      class="inputTag" 
                      v-model="guest_name" 
                      :required="nameValid"
                    >
                    <img class="label_eimage" src="@/assets/black_label.png" />
                    <label class="label_name">Name</label>
                    </input>
                    <input 
                      class="inputTag ml-3" 
                      v-model="guest_email" 
                      :required="emailValid"
                    >
                    <img class="label_eimage" src="@/assets/black_label.png" />
                    <label class="label_name">Email</label>
                    </input>
                  </div>
                  <div class="mt-3">
                    <input class="inputTag" v-model="guest_address" :required="addressValid">
                      <img class="label_eimage" src="@/assets/black_label.png" style="width: 63px;"/>
                      <label class="label_name">Address</label>
                    </input>
                    <input 
                      class="inputTag ml-3" 
                      v-model="guest_mobile"
                      :required="mobileValid"
                      maxlength="10" 
                      oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
                    >
                      <img class="label_eimage" src="@/assets/black_label.png" />
                      <label class="label_name">Mobile</label>
                    </input>
                  </div>
                  <div v-if="otpFlag" class="mt-3">
                    <span
                      v-if="sendingOtp"
                      class="otpmsg" style="left: 248px; font-size: 14px; margin-top: 14px; text-size-adjust: 50%;">
                        OTP Sending...
                    </span>
                    <span
                      v-if="!sendingOtp" 
                      style="font-size: 12px; color: green; width: 226px; position: absolute; margin-left: -73px; text-size-adjust: 50%;">
                      <a style="color: red;">*</a>
                      OTP has successfully sent to your registered Mobile & Email.Please enter any one
                    </span>
                    <input 
                      oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
                      class="inputotpTag"
                      style="margin-left: 178px;" 
                      v-model="guest_otp">
                      <img style="position: absolute; margin-left: -140px; width: 38px;" src="@/assets/black_label.png" />
                      <label style="position: absolute; margin-left: -135px; margin-top: -11px; color: #8b8e94;">OTP</label>
                    </input>
                  </div>
                  <button v-if="!ToolFlag" class="startCustomButton" @click="otpSender()">Send OTP</button>
                  <div v-if="ToolFlag" class="resender" @click="otpSender()">Resend OTP</div>
                  <button v-if="ToolFlag" :disabled="checkValid" class="startCustomButton" @click="redirect(buttonTool)">{{buttonTool}}</button>
                </center>
              </va-card>
            </center>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ScreenSizeDetector from 'screen-size-detector';
import Vue from 'vue';
import vueResource from 'vue-resource';
import config from '../../i18n/en.json';
import mobileDetector from 'mobile-device-detect';
Vue.use(vueResource);

export default {
  created () {
    var screenSize=new ScreenSizeDetector().width;
    var scaleRatioNumber=screenSize<600 ? (0.00125*screenSize) : 1;
    this.topScaleRatio="zoom: "+scaleRatioNumber+";";
    this.tokenValidity();
    this.analytics('visitors', null);
  },
  data () {
    return {
      device: false,
      modalForm: false,
      guest_name: '',
      guest_email: '',
      guest_address: '',
      guest_mobile: '',
      guest_otp: '',
      otpFlag: false,
      buttonTool: '',
      userOtp: '',
      validity: false,
      nameValid: false,
      emailValid: false,
      addressValid: false,
      mobileValid: false,
      sendingOtp: true,
      topScaleRatio:'',
      ToolFlag: false,
      videoPath: ''
    }
  },
  computed: {
    checkValid() {
      if(this.guest_name && this.guest_email && this.guest_address && this.guest_address && this.guest_mobile && this.guest_otp){
          return false
      }else{ return true };
    },
    otpValid() {
      if(this.guest_mobile){
          return false
      }else{ return true };
    }
  },
  methods: {
    tokenValidity() {
      if(Vue.$cookies.get('x-access-token')) {
        this.$http.get(config.menu.host + 'content/hepi/token_validity').then(validity => {
          this.validity = true;
        }, err => {
          this.validity = false;
        })
      }else{
        this.validity = false
      }
    },
    modal (type) {
      if(!this.validity){
        this.modalForm = true;
        this.resetForm();
        this.buttonTool = (type == 'custom') ? 'Start Customization' : 'Place Order';
      }else{
        return this.$router.push({ name: 'branding' });
      };
    },
    closeModal(){
      this.modalForm = false;
    },
    placeOrder() {
      return this.$router.push({ name: 'shipping' });
    },
    redirect (type) {
      if(type == 'Start Customization') {
        var payload = {
          'full_name' : this.guest_name,
          'email'     : this.guest_email,
          'address'   : this.guest_address,
          'otp'       : this.guest_otp,
          'mobile'    : this.guest_mobile,
          'token'     : this.$cookies.get('x-access-token')
        };
        this.$http.post(config.menu.host + 'content/verification', payload).then(response => {
          if(response.body.msg == 'verified') {
            this.analytics('user_visitor', response.body.user_id);
            Vue.$cookies.set('user_id', response.body.user_id);
            Vue.$cookies.set('x-access-token', response.body.token);
            Vue.$cookies.set('mobile', response.body.mobile);
            this.$router.push({ name: 'branding' });
          };
        }, err => {
          return Vue.notify({text:'Invalid OTP', type: 'error'});
        });
      };
    },
    otpSender() {
      this.nameValid = false;
      this.emailValid = false;
      this.addressValid = false;
      this.mobileValid = false;
      if(!this.guest_name) return this.nameValid = true; 
      if(!this.guest_email) return this.emailValid = true;
      if(!this.guest_address) return this.addressValid = true;
      if(!this.guest_mobile) return this.mobileValid = true;
      this.otpFlag = true;
      this.userOtp = '';

      var payload = {
        mobile : this.guest_mobile,
        full_name : this.guest_name,
        email : this.guest_email
      };

      this.$http.post(config.menu.host + 'content', payload).then(response => {
        if(response.body) {
          this.ToolFlag = true;
          this.sendingOtp = false;
        }
      });
    },
    resetForm() {
      this.guest_name = '';
      this.guest_address = '';
      this.guest_email = '';
      this.guest_mobile = '';
      this.guest_otp = '';
    },
    mblredirect() {
      return this.$router.push({ name: 'branding' });
    },
    analytics(type, userId) {
      function QueryStringToJSON () {
        var pairs = location.search.slice(1).split('&')
        var result = {}
        pairs.forEach(function (pair) {
          pair = pair.split('=')
          result[pair[0]] = decodeURIComponent(pair[1] || '')
        })
        return JSON.parse(JSON.stringify(result))
      }
      var query_string = QueryStringToJSON()
      var visitors = visitor;
      var payload = {
        'ip' : visitors.ip.address,
        'country' : visitors.geo.countryName,
        'city' : visitors.geo.city,
        'device' : visitors.device.screen.resolution,
        'browser' : visitors.browser.name,
        'os' : visitors.os.name,
        'co_ordinates' : visitors.geo.coordinates,
        'postal_code' : visitors.geo.postalCode,
        'visit_count' : visitors.session.visitCount,
        'utm' : (query_string && query_string.utm) ? query_string.utm : ''
      };
      payload.user_id = (userId) ? userId : '';
      this.$http.post(config.menu.host + 'content/visitor/visitors', payload).then(response => {});
    }
  },
}
</script>

<style>
  body {
    background-image: url("../../assets/new_repeat.jpg");
    background-position: left top;
    background-repeat: repeat;
    background-size: 10px;
    -webkit-text-size-adjust: 120%;
  }

  .home_container {
    width: 800px;
    margin: 0 auto;
  }

  button:disabled {
    opacity: 0.4;
    cursor: default;
  }

  input:required {
    border: red solid 1px;
  }

  button {
    cursor: pointer;
  }

  .min_max {
    width: 808px;
    margin-top: -116px;
    margin-bottom: -160px;
  }

  .Dlogo {
    height: 130px;
    width: 130px;
  }

  .home_screen {
    background-image: url("../../assets/circlebg.png");
    background-repeat: no-repeat;
    background-size: 743px;
    position: relative;
    height: 751px;
    width: 800px;
    text-align: center;
    background-position: center;
  }

  .Dcompany_logo {
    margin-left: -614px;
    margin-bottom: -75px;
  }

  .home_text {
    font-size: 23px;
    color: white;
    font-weight: 600;
    margin-top: -21px;
    text-size-adjust: 50%;
  }

  .home_text2 {
    font-size: 23px;
    color: #8b8e94;
    text-size-adjust: 50%;
  }

  .buttonSet {
  }

  .Dvideo_screen {
    position: absolute;
    width: 685px;
    top: 133px;
    left: 60px;
  }

  .button_tag {
    margin: 10px;
    padding: 15px 32px;
    font-size: 16px;
    width: 230px;
    border-radius: 10px;
    text-size-adjust: 50%;
  }

  .modalfade {
    background-color: black;
  }

  .popup_image {
    position: absolute;
    z-index: 1;
    height: 130px;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 115px;
  }

  .popupCard {
    background: #0f0f0f;
    -webkit-box-shadow: none;
    box-shadow: black;
    width: 720px;
    position: relative;
    top: 196px;
    left: 5px;
    border-radius: 23px;
  }

  .popupView {
    z-index: 1000;
    position: fixed !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: -webkit-box;
    -webkit-box-pack: center;
    background: rgba(0, 0, 0, 0.9);
  }

  .inputTag {
    background-color: #0f0f0f;
    border: 1px solid #3f3f3f;
    border-radius: 8px;
    height: 50px;
    width: 296px;
    text-indent: 23px;
    color: white;
    text-size-adjust: 50%;
  }

  .label_name {
    position: absolute;
    color: #808080;
    font-size: 15px;
    text-size-adjust: 50%;
    margin-left: -272px;
    margin-top: -11px;
  }

  .label_image {
    position: absolute;
    left: 67px;
    height: 23px;
    top: 117px;
    width: 49px;
  }

  .label_eimage {
    position: absolute;
    width: 52px;
    margin-left: -279px;
    margin-top: -9px;
  }

  .label_email {
    position: absolute;
    left: 383px;
    top: 117px;
    color: #808080;
    font-size: 15px;
    text-size-adjust: 50%;
  }

  .label_aimage {
    position: absolute;
    width: 63px;
    left: 65px;
    top: 194px;
  }

  .label_aname {
    position: absolute;
    left: 69px;
    top: 191px;
    color: #808080;
    font-size: 15px;
    text-size-adjust: 50%;
  }

  .label_pimage {
    position: absolute;
    width: 54px;
    left: 380px;
    top: 193px;
  }

  .label_pname {
    position: absolute;
    left: 385px;
    top: 190px;
    color: #808080;
    font-size: 15px;
    text-size-adjust: 50%;
  }

  .otpButton {
    position: absolute;
    left: 571px;
    height: 49px;
    width: 89px;
    border-radius: 8px;
    border: white;
    text-size-adjust: 50%;
  }

  .startCustomButton {
    height: 39px;
    width: 172px;
    margin-top: 34px;
    background-color: #f67218;
    border: #f67218;
    border-radius: 6px;
    color: white;
    text-size-adjust: 50%;
  }

  .inputotpTag {
    background-color: #0f0f0f;
    border: 1px solid #3f3f3f;
    border-radius: 8px;
    height: 50px;
    width: 159px;
    text-indent: 23px;
    color: white;
    text-size-adjust: 50%;
  }

  .label_oimage {
    position: absolute;
    top: 262px;
    left: 378px;
    height: 13px;
  }

  .label_otp {
    position: absolute;
    top: 258px;
    left: 383px;
    color: #808080;
    font-size: 15px;
  }

  .otpmsg {
    position: absolute;
    color: #626262;
  }

  .popup_line {
    position: absolute;
    width: 633px;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 194px;
  }

  .resender {
    position: absolute;
    left: 592px;
    margin-top: -65px;
    font-size: 13px;
    cursor: pointer;
    text-size-adjust: 50%;
  }
</style>
